

window.WebFontConfig = {
  google: { families: ['Noto+Sans+JP'] },
  active: function() {
    sessionStorage.fonts = true;
  }
};

(function() {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();


function resizeWindow(){
  //console.log('Resize');
  visual_height_fix();
}

window.addEventListener('resize', resizeWindow);


$(window).on("load", function () {
  $('body').removeClass('top');

  
  $("main").fadeIn(0);
  $("header").fadeIn(0);
  $("footer").fadeIn(0);
  


  if (_ua.Mobile) {
    visual_height_fix();
  } else {
    visual_height_fix();
 
  }




 
 



  var title = $(".about_title .company");
  var title2 = $(".about_title .mainen");
  var title3 = $(".about_title h1");


});

$(window).on("load", function () {

});

  inview();
  
 
  pagetop();
  
  smooth();
  faq();
  career();
  menuoverlay();
  pc_menu();
function menuoverlay(){
  if($("body").hasClass("is--global-navi-open")){
    
  }
}


function faq() {

  $(".faq_title").on("click",function () {
      $(this).toggleClass('isactive');
      $(this).next(".faq_answer").stop().slideToggle();
      $(this).parent(".faq_box").toggleClass('active');
  });
  $(".faq-button").on("click",function () {
      $(this).prev(".faq-text").stop().slideToggle();
      $(this).parent(".faq-wrapper").toggleClass('active');
  });

}


$(document).on('click','.lity-close',function(){

  var video = $('#video').get(0);
          video.pause();            // video 一時停止
          video.currentTime = 0;    // 次回再生時に最初から再生するなら再生時間を０に
  });
  $(document).on('click', '.lity-wrap',function(){

 var video = $('#video').get(0);
          video.pause();            // video 一時停止
          video.currentTime = 0;    // 次回再生時に最初から再生するなら再生時間を０に
 });

 function movie_play_sp(){
   var video = document.querySelector('#video');
   var video2 = document.querySelector('#video2');
 var video_btn = document.querySelector('#video-btn');
 var is_playing = false;
 var is_playing2 = false;

 $("#video-btn").on("click",function(){
   if (!is_playing) {
     video.play();

     is_playing = true;

   } else {
     video.pause();
     is_playing = false;
;
   }
 })


 $(".video-btn2").on("click",function(){
   if (!is_playing2) {
     video2.play();

     is_playing2 = true;

   } else {
     video2.pause();
     is_playing2 = false;
;
   }
 })


 }

function movie_play(){
var video = document.querySelector('#video');
var video2 = document.querySelector('#video2');
var video_btn = document.querySelector('#video-btn');
var is_playing = false;
var is_playing2 = false;

$("#video-btn").on("click",function(){
 if (!is_playing) {
   video.play();
   video.volume = 0.5;
   is_playing = true;
   $(".video-btn").addClass("active");
   $(".play_btn").addClass("active");
   $("#movie").addClass("active");
 } else {
   video.pause();
   is_playing = false;
   $(".video-btn").removeClass("active");
   $(".play_btn").removeClass("active");
   $("#movie").removeClass("active");
 }
});

$(".video-btn2").on("click",function(){
 if (!is_playing2) {
   video2.play();
   video2.volume = 0.5;
   is_playing2 = true;
   $(".video-btn2").addClass("active");
   $(".play_btn2").addClass("active");
   $("#movie.m2").addClass("active");
 } else {
   video2.pause();
   is_playing2 = false;
   $(".video-btn2").removeClass("active");
   $(".play_btn2").removeClass("active");
   $("#movie.m2").removeClass("active");
 }
})

}


function career(){
  var t = $(".year_title");
  var c = $(".caree_main_cont");

  t.click(function(event){
    $(this).next("div").slideToggle();
    $(this).toggleClass("active");
  })
}

function inview() {
  $(".inview").on("inview", function (event, visible, topOrBottomOrBoth) {
    if (visible) {
      $(this).addClass("active");
    } else {
    }
  });
}



function pc_menu() {

  $("#menu").on("click",function(){
    $("#gnav").toggleClass("active");
    gsap.to("#gnav .bgb", { x:"0%", duration: 0.3 });
  });

  $("#back_btn a").on("click",function(event){
    event.preventDefault();
    $("#gnav").toggleClass("active");
    gsap.to("#gnav .bgb", { x:"100%", duration: 0.3 });
  });


}

var bodyElm = $("body");
var scrollPosi;
function bodyFix() {
  scrollPosi = $(window).scrollTop();
  bodyElm.css({
    position: "fixed",
    width: "100%",
    "z-index": "1",
    top: -scrollPosi,
  });
}

function visual_height_fix(){
  var nH = $('header').outerHeight();
  var wH = $(window).height();
  var hH = wH - nH;
  $('#hero').css('height', hH + 'px');
  //$('#hero').css('margin-top', nH + 'px');
  //$('#video-area').css("height",hH);
  $('#video-area').css("top",nH);
}






function blankdwindow(){
  function ready(fn) {
    if (document.readyState != 'loading') {
      fn();
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    } else {
      document.attachEvent('onreadystatechange', function() {
        if (document.readyState != 'loading')
          fn();
      });
    }
  }
  ready(function() {
    var website = window.location.hostnam;
    var internalLinkRegex = new RegExp('^((((http:\\/\\/|https:\\/\\/)(www\\.)?)?'
    //+ website
    + ')|(localhost:\\d{4})|(\\/.*))(\\/.*)?$', '');
  var anchorEls = document.querySelectorAll('a');
    var anchorElsLength = anchorEls.length;
  
  for (var i = 0; i < anchorElsLength; i++) {
    var anchorEl = anchorEls[i];
    var href = anchorEl.getAttribute('href');
  if (!internalLinkRegex.test(href)) {
    anchorEl.setAttribute('target', '_blank');
  anchorEl.setAttribute('rel', 'noopener nofollow');
      }
    }
  });
}



function coming() {
  $(".coming a").click(function (event) {
    return false;
    event.preventDefault();
  });
  $(".nolink a").click(function (event) {
    event.preventDefault();
  });
  $("a.nolink").click(function (event) {
    event.preventDefault();
  });
}

function ipad_view() {
  if (
    ua.indexOf("iPhone") > 0 ||
    ua.indexOf("iPod") > 0 ||
    (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
  ) {
    $("head").prepend(
      '<meta name="viewport" content="width=device-width,initial-scale=1">'
    );
  } else if (_ua.Tabvar) {
    $("head").prepend('<meta name="viewport" content="width=1280">');
    $("body").addClass("ipad_view");
  }
}

function sp_menu() {



  var hpdd = $(".header_inner").outerHeight();
  $("main.undercont").css("margin-top",hpdd);

  $(".link_btn").on("click", function (event) {
    event.preventDefault();
    $(this).toggleClass("active");
    $(this).next(".morelink").slideToggle();
  });




 


  var state = false;
  $("#spmenu").on("click", function () {
  if(state == false){
    $("#logo").toggleClass("menu_open");
    //$("header").hide();
    $(this).toggleClass("active");
    $(".overlay").toggleClass("is-open");
    $(".rec_menu").toggleClass("is-open");
    $("body").toggleClass("is-fixed");
    $("#recruit_menu .inner").toggleClass("is-open");
    $("#recruit_menu").toggleClass("is-open");
    $("#logomark_normal").toggleClass("white");
    $("p.logotxt").toggleClass("is-active");

    //state=true;
  
}else{

  $(this).toggleClass("active");
  $("#logo").toggleClass("menu_open");
  $("#gnav").toggleClass("is-open");
  $("#gnav_pc").toggleClass("is-hopen");
  $("body").toggleClass("is-fixed");
  $("#logomark").toggleClass("white");
  $("header").removeClass("isopen");
  $("#logomark_normal").toggleClass("white");
  $("p.logotxt").toggleClass("is-active");
  state=false;
}
});

$("h2.mainme").on("click",function(){
  $(this).toggleClass("active");
  $(this).next("ul.subs").slideToggle();
});


}

function smooth() {
  // #で始まるアンカーをクリックした場合に処理
  $('a[href^="#"].smooth').click(function () {
    var hh = $("header").outerHeight();

    var href = $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? "html" : href);
    // 移動先を数値で取得
    if (_ua.Mobile) {
      var position = target.offset().top - 50;
    } else if ($(this).hasClass("smin")) {
      var position = target.offset().top - hh;
    } else {
      var position = target.offset().top;
    }
    gsap.to(window, { duration: 0.5, scrollTo: position, ease:"easeOutexpo" });
    return false;
  });

  var h = $(window).height();
  var hh = $("header").outerHeight();
  var ss = h - hh;
  //$("section#visual").css("height" , ss);
}



function pagetop() {
  $(window).scroll(function () {
    var pagetop = $("#pagetop");
    if ($(this).scrollTop() > 100) {
      pagetop.addClass("active");
    } else {
      pagetop.removeClass("active");
    }
  });
  $("#pagetop a").click(function (event) {
    event.preventDefault();
    gsap.to(window, { duration: 0.5, scrollTo: 0,ease:"easeOutexpo" });
  });


}


const buttonElement = document.querySelector("#menu a");
const buttonBgElement = buttonElement?.querySelector(".bg");

const handleMouseEnterLeave = (event) => {
  if (!buttonElement || !buttonBgElement) {
    return;
  }
  const mouseTop = event.offsetY;
  const mouseLeft = event.offsetX;
  buttonBgElement.style.translate = `${mouseLeft}px ${mouseTop}px`;
};

buttonElement?.addEventListener("mouseenter", handleMouseEnterLeave);
buttonElement?.addEventListener("mouseleave", handleMouseEnterLeave);


// メニューの初期化関数を別途定義
function initializeMenu() {
  pc_menu();
}




// DOMContentLoadedとbarba.jsの両方で初期化を行う
function initializePage() {
  const namespace = document.querySelector('[data-barba="container"]')?.dataset.barbaNamespace;
  
  initializeMenu();
  smooth();
  // 共通の初期化
  initializeCommonFunctions();

  // 現在のページに応じた初期化
  switch (namespace) {
    case 'top':
      window.initializeSlider();
      inview();
      pagetop();
      visual_height_fix();
      if (_ua.Mobile) {
        movie_play_sp();
      } else {
        movie_play();
      }
      break;
    case 'topmessage':
      inview();
      pagetop();
      // トップメッセージページ特有の初期化
      break;
    case 'neu':
      // neuページ特有の初期化
      inview();
      pagetop();
      // その他必要な初期化処理
      break;
  }
}



// 共通の初期化関数
function initializeCommonFunctions() {
  smooth();
  faq();

  coming();
  

}



// barba.jsの設定
barba.init({
  transitions: [{
    leave: function(data) {
      // クリーンアップ処理
      if (window.sliderInstance) {
        window.sliderInstance.renderer.dispose();
        window.sliderInstance.scene.remove(window.sliderInstance.scene.children[0]);
        window.sliderInstance = null;
      }
      return new Promise(function (resolve, reject) {
        leaveAnimation(data.current.container);
        setTimeout(function () {
          resolve();
        }, 800)
      });
    },
    enter: function(data) {
      // CSSの再読み込みを確認
      console.log('Entering page:', data.next.namespace);
      enterAnimation(data.next.container);
    },
    after: function(data) {
      // 遷移後の初期化
      console.log('After transition:', data.next.namespace);
      initializePage();
      // メニューを確実に初期化
      setTimeout(() => {
        initializeMenu();
      }, 100); // わずかな遅延を追加
    }
  }],
  views: [
    {
      namespace: 'top',
      afterEnter() {
        // トップページ特有の初期化処理
        window.initializeSlider();
        
      }
    },
    {
      namespace: 'topmessage',
      afterEnter() {
        // トップメッセージページ特有の初期化処理
        
      }
    },
    {
      namespace: 'neu',
      afterEnter() {
        // トップメッセージページ特有の初期化処理
        
      }
    }
  ]

});


// CSS読み込みの確認
function ensureStylesLoaded() {
  return new Promise((resolve) => {
    const links = [...document.getElementsByTagName('link')];
    const pendingLinks = links.filter(link => 
      link.rel === 'stylesheet' && !link.loaded
    );

    if (pendingLinks.length === 0) {
      resolve();
      return;
    }

    let loadedLinks = 0;
    const linkLoaded = () => {
      loadedLinks++;
      if (loadedLinks === pendingLinks.length) {
        resolve();
      }
    };

    pendingLinks.forEach(link => {
      link.addEventListener('load', linkLoaded);
    });
  });
}


const eventDelete = e => {
  if (e.currentTarget.href === window.location.href) {
    e.preventDefault()
    e.stopPropagation()
    return
  }
}

const links = [...document.querySelectorAll('a[href]')]
links.forEach(link => {
  link.addEventListener('click', e => {
    eventDelete(e)
  }, false)
})

// animation.jsの設定
// 現在のページを離れる時のアニメーション
function leaveAnimation(e) {

  var tl = gsap.timeline();
  tl.set(".mask", {y:"-100%"});
  tl.to(".mask", {y:"0%", duration: 0.5});
  $("#gnav").removeClass("active");
    gsap.to("#gnav .bgb", { x:"100%", duration: 0.3 });
  
}
// 次のページを表示する時のアニメーション
function enterAnimation(e) {
  $(".mask").css({y:"0%"});
  var tl = gsap.timeline();
  tl.to(".mask", {y:"100%", duration: 0.8});

  
}